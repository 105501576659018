/* You can add global styles to this file, and also import other style files */

@import "~bootstrap-icons/font/bootstrap-icons.css";
@import 'ngx-multiple-dates/prebuilt-themes/indigo-pink.css';
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");



html,
body {
  height: 100%;
  font-family: 'Ubuntu', sans-serif;
}

:root {
  --bg-color: #0f0f0f;
  --accent-color: #ee9200;
  --primary-color: #e60000;
  --secondary-color: #0530ad;
  --success-color: #4caf50;
  --app-blue: hsl(205, 78%, 15%);
  --print-color: hsl(0, 0%, 0%);
  --white: #ffffff;
  --text-color: white;
  --text-color-light: white;
  --text-color-dark: black;
  --default-font: 'Nunito Sans', sans-serif;
  --header-font: 'Oswald', sans-serif;
}



h1,
h2,
h3,
h4,
h5,
h6,
.header-font {
  font-family: var(--header-font);
}


.app-bg {
  background-color: var(--bg-color) !important;
  color: white;
}

.app-bg-darker {
  background-color: #030303;
  color: white;
}

.app-bg-lighter {
  background-color: #171717;
  color: white;
}

.app-bg-danger,
.danger-bg {
  background-color: var(--primary-color);
  color: white;
}

.app-bg-white,
.white-bg {
  background-color: var(--white);
  color: #202020;
}

.app-bg-warning,
.warning-bg {
  background-color: var(--accent-color);
  color: white;
}

.blue-bg,
.app-bg-blue {
  background-color: var(--app-blue);
  color: white;
}

.text-dark {
  color: var(--text-color-dark);
}

.text-light {
  color: var(--text-color-light);
}

.text-color {
  color: var(--text-color);
}

.text-shadow-dark {
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.accent-text,
.accent-text {
  color: var(--accent-color);
}

.primary-text,
.red-text,
.primary-text {
  color: var(--primary-color) !important;
}



.link:hover,
.link.active,
.link:active {
  color: var(--primary-color);
  transition: all .25s ease;
}

.link.active {
  border-bottom: 1px solid var(--primary-color);
}

.link {
  color: var(--text-color);
  font-size: large;
  text-decoration: none;
  cursor: pointer;
}

.print>.link,
.print>span.accent-color {
  color: inherit !important;
  font-size: inherit;
}

.left-border-panel {
  border-left: 4px solid var(--primary-color);
  padding-left: 16px;
}

.right-border-panel {
  border-right: 4px solid var(--primary-color);
  padding-right: 16px;
}

.auto-height {
  height: auto;
}

.box {
  z-index: 100;
  padding: 24px;
}


.banner-header>h1,
.banner-header {
  font-size: 1.75em;
}

.banner-header>button {
  font-weight: bold;
}

.checked-icon {
  width: 100%;
  max-width: 48px;
}

.page-video {
  width: 100%;
  height: auto;
}



.zoom {
  transition: transform .2s;
  /* Animation */
}

.zoom:hover {
  transform: scale(1.05);
  /* (150% zoom)*/
}



.large-text {
  font-size: 1.25rem;
}


.small-text {
  font-size: 0.90rem !important;
}

.display-5 {
  font-size: 3rem;
}

.display-6 {
  font-size: 2.5rem;
}

.display-7 {
  font-size: 2rem;
}


.full-height {
  min-height: 100vh;
}

.bg-transparent {
  background-color: transparent !important;
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.w-lg-75 {
  width: 75%;
}

.align-center {
  vertical-align: middle;
}

.mat-horizontal-stepper-header-container {
  display: none !important;
}

img {
  max-width: 100%;
  display: block;
}

.text-bold {
  font-weight: bold;
}

.grid-container {
  display: grid;
  width: 100%;
  height: 100%;
  place-items: center;
}

.center-contanier {
  place-items: center;
}

.btn {
  border-radius: 0;
}

a[role="button"].app-bg-danger:hover,
button.app-bg-danger:hover {
  background-color: hsl(0, 100%, 30%);
  color: white;
}

a[role="button"].app-bg-blue:hover,
button.app-bg-blue:hover {
  background-color: hsl(239, 100%, 8%);
  transition: all .25s ease;
  color: white;
}

.button {
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 0;
  line-height: 1.5;
  border: 1px solid transparent;
  background-color: transparent;
  color: var(--text-color);
  text-align: center;
  vertical-align: middle;
  transition: all .15s ease;
}

.button-block {
  display: block;
  width: 100%;
}

.button:disabled,
.button.disabled {
  opacity: 50%;
}

.button-large {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}


.button-outline {
  border: 2px solid var(--text-color);
}

/* Red/Danger/Primary */

.button-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--text-color);
}

.button-primary:hover,
.button-primary.hover,
.button-primary.active,
.button-primary:active {
  background-color: hsl(0, 100%, 30%);
  border-color: hsl(0, 100%, 30%);
}

.button-outline-primary {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.button-outline-primary:hover,
.button-outline-primary.hover,
.button-outline-primary:active,
.button-outline-primary.active {
  background-color: var(--primary-color);
  color: var(--text-color);
}

/* Secondary Color */
.button-secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.button-secondary:hover,
.button-secondary.hover,
.button-secondary.active,
.button-secondary:active {
  background-color: hsl(239, 100%, 8%);
  border-color: hsl(239, 100%, 8%);
}

.button-outline-secondary {
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.button-outline-secondary:hover,
.button-outline-secondary.hover,
.button-outline-secondary:active,
.button-outline-secondary.active {
  background-color: var(--secondary-color);
  color: var(--text-color-light);
}

/* Accent Color */
.button-accent {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  color: var(--text-color-dark);
}

.button-accent:hover,
.button-accent.hover,
.button-accent.active,
.button-accent:active {
  color: var(--text-color-light);
  background-color: #ee8700;
  border-color: #ee8700;
}

.button-outline-accent {
  border-color: var(--accent-color);
  color: var(--accent-color);
}

.button-outline-accent:hover,
.button-outline-accent.hover,
.button-outline-accent:active,
.button-outline-accent.active {
  background-color: var(--accent-color);
  color: var(--text-color-dark);
}

.page-break {
  page-break-after: always;
  break-after: always;
}

.pulse-animation {
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1.0;
  }

  100% {
    opacity: 0.2;
  }
}


.w-lg-50 {
  width: 50%;
}


@keyframes primaryPulse {
  0% {
    background-color: hsl(0, 100%, 10%);
  }

  50% {
    background-color: hsl(0, 100%, 40%);
  }

  100% {
    background-color: hsl(0, 100%, 10%);
  }
}

.warning-box {
  text-align: center;
  padding: 1rem;
  margin: 1rem;
}

.primary-alert {
  animation: primaryPulse 2s infinite;
}

.primary-alert-fast {
  animation: primaryPulse 1s infinite;
}


/* .mat-radio-outer-circle {

  border-radius: 10% !important;
} */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #171717;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(114, 114, 114);
}

@media only screen and (min-width: 769px) {
  .card-image {
    height: 35%;
  }

  .service-card {
    height: 100%;
  }

  .checked-icon {
    max-width: 64px;
  }

  .page-row {
    min-height: 105vh;
  }
}

@media only screen and (max-width: 768px) {

  .w-lg-75,
  .w-lg-50 {
    width: 100%;
  }

  .page-row {
    height: auto;
    padding: 24px 4px;
  }

  .display-3 {
    font-size: 3.25rem;
  }

  .display-5 {
    font-size: 2rem;
  }

  .display-6 {
    font-size: 1.5rem;
  }

  .display-7 {
    font-size: 1.25rem;
  }

  .zoom:hover {
    transform: none;
  }

  .mat-horizontal-content-container {
    padding: 0 4px 4px 4px !important;
  }

}



@media print {

  .footer,
  app-footer {
    visibility: hidden;
    display: none;
  }


  @page {
    size: A4;
  }

  nav {
    visibility: hidden;
    display: none;
  }

  body {
    font-family: Arial, Helvetica, sans-serif;
  }

}

.price-highlight {
  animation: text-pulse 1.5s infinite;
  font-weight: bold;
  font-size: larger;
}

.text-larger {
  font-size: 1.1rem;
}

@keyframes text-pulse {
  0% {
    color: black;
  }



  50% {
    color: hsl(0, 100%, 50%);
  }


  100% {
    color: black;
  }
}
